import { createSelector } from '@reduxjs/toolkit';

import { Actions } from 'actions';
import { getAccountsUsage, getAccountUsage } from 'lib/api';
import { sn } from 'lib/safe';

type stateT = {
  +asError: boolean,
  +errorMessage: string,
  +loading: boolean,
  +accounts: Array<accountT>,
  +monthlyUsage: { limit: number, total: number, month: string },
  +selected: string,
  +stats: statsT,
  +limitations: {},
  +usage: {
    shortens: number,
    pixels: number,
    teamMembers: number,
    workspaces: number,
    brandedDomains: number,
  },
};

// ACTIONS
type errorT = { message: string };
export type actionT =
  | { type: 'LOAD_ACCOUNTS' }
  | { type: 'UPDATE_ACCOUNT', account: accountT }
  | {
      type: 'UPDATE_ACCOUNTS_USAGE',
      usage: {
        shortens: number,
        pixels: number,
        teamMembers: number,
        workspaces: number,
        brandedDomains: number,
      },
    }
  | { type: 'LOAD_ACCOUNTS_SUCCESS', data: { accounts: Array<accountT> } }
  | { type: 'LOAD_ACCOUNTS_ERROR', error: error }
  | { type: 'UPDATE_ACCOUNT_LIMITS', limitations: {} }
  | { type: 'SWITCH_ACCOUNT', accountId: string }
  | {
      type: 'UPDATE_ACCOUNT_USAGE',
      monthlyUsage: { limit: number, total: number, month: string },
    };

export function loadAccounts(): actionT {
  return {
    type: 'LOAD_ACCOUNTS',
  };
}

export function updateAccount(account: accountT): actionT {
  return {
    type: 'UPDATE_ACCOUNT',
    account,
  };
}

export function loadAccountsSuccess(data: { accounts: Array<accountT> }): actionT {
  return {
    type: 'LOAD_ACCOUNTS_SUCCESS',
    data,
  };
}

export function updateAccountLimitations(limitations: {}): actionT {
  return {
    type: 'UPDATE_ACCOUNT_LIMITS',
    limitations,
  };
}

export function loadAccountsError(error: errorT): actionT {
  return {
    type: 'LOAD_ACCOUNTS_ERROR',
    error,
  };
}

export function switchAccount(accountId: string): actionT {
  return {
    type: 'SWITCH_ACCOUNT',
    accountId,
  };
}

export function updateUsage(monthlyUsage: { limit: number, total: number, month: string }): actionT {
  return {
    type: 'UPDATE_ACCOUNT_USAGE',
    monthlyUsage,
  };
}

export function updateAccountsUsage({
  usage,
}: {
  usage: {
    shortens: number,
    pixels: number,
    teamMembers: number,
    workspaces: number,
    brandedDomains: number,
  },
}): actionT {
  return {
    type: 'UPDATE_ACCOUNTS_USAGE',
    usage,
  };
}

// STORE
export function accounts(state: stateT, action: actionT): stateT {
  const initalAccountsState: stateT = {
    asError: false,
    errorMessage: '',
    loading: true,
    accounts: [],
    monthlyUsage: { limit: -1, total: -1, month: '' },
    stats: {},
    selected: '',
    limitations: {},
    usage: {},
  };

  state = state || initalAccountsState;
  let { accounts } = state;

  switch (action.type) {
    case 'UPDATE_ACCOUNT_USAGE':
      return { ...state, monthlyUsage: action.monthlyUsage };
    case 'UPDATE_ACCOUNTS_USAGE':
      return {
        ...state,
        usage: action.usage,
      };
    case 'UPDATE_ACCOUNT_LIMITS':
      return { ...state, limitations: action.limitations };
    case 'SWITCH_ACCOUNT': {
      // Find account using account id
      const acc = state.accounts.find((el) => el.id === action.accountId);

      // Do not switch to non existing account
      return acc
        ? {
            ...state,
            selected: action.accountId,
            limitations: acc.orgLimitations ?? acc.limitations,
          }
        : state;
    }
    case 'LOAD_ACCOUNTS':
      return initalAccountsState;
    case 'UPDATE_ACCOUNT': {
      const newAccount: accountT = action.account;
      const oldAccount: accountT = accounts.find((account) => account.id === newAccount.id);

      accounts = accounts.filter((account) => account.id !== newAccount.id);

      if (oldAccount) {
        newAccount.convPixels = oldAccount.convPixels;
      }

      accounts.push(newAccount);

      return { ...state, accounts };
    }
    case Actions.api.account.update.success().type: {
      const newAccount = action.payload.account;

      accounts = accounts.filter((account) => account.id !== newAccount.id);
      accounts.push(newAccount);

      return { ...state, accounts };
    }

    case Actions.api.account.signUp.success().type:
      return { ...state };
    case 'LOAD_ACCOUNTS_SUCCESS': {
      let selected = '';
      let { limitations } = action.data;

      if (state.selected.length === 0 && action.data.accounts.length > 0) {
        const { id, limitations: accLimitations, orgLimitations } = action.data.accounts[0];

        selected = id;
        limitations = orgLimitations ?? accLimitations;
      }

      return {
        ...state,
        loading: false,
        accounts: action.data.accounts,
        selected,
        limitations,
      };
    }
    case 'LOAD_ACCOUNTS_ERROR':
      return {
        ...state,
        loading: false,
        asError: true,
        errorMessage: action.error.message,
      };
    case Actions.api.account.utms.sources.remove.success().type:
    case Actions.api.account.utms.mediums.remove.success().type:
    case Actions.api.account.utms.campaigns.remove.success().type:
    case Actions.api.account.platforms.remove.success().type:
    case Actions.api.account.settings.analytics.reset.success().type:
    case Actions.api.account.tags.remove.success().type: {
      let { accounts } = state;

      const updatedAccount = action.payload.account;

      accounts = accounts.filter((a) => a.id === !updatedAccount.id);
      accounts.push(updatedAccount);

      return {
        ...state,
        accounts,
      };
    }
    default:
      return state;
  }
}

// Selectors
const selectRoot = (state) => state.accounts;

export const selectAccounts = createSelector(selectRoot, (root) => root.accounts);

export const selectCurrentAccountId = createSelector(selectRoot, (root) => root.selected);

export const selectCurrentAccount: ?accountT = createSelector(
  selectAccounts,
  selectCurrentAccountId,
  (accounts, currentAccountId) => {
    if (!accounts) {
      throw new Error('Unable to get accounts from state');
    }

    return accounts.find((account) => currentAccountId === account.id);
  },
);

export const selectAccountsLimitations = createSelector(selectRoot, (root) => root.limitations);

export function currentGroup(state: stateT): ?saLinkT {
  if (!state.selectedGroupId) {
    return {};
  }

  if (!state.filters) {
    throw new Error('Unable to get currentGroup from state ', state);
  }

  return state.filters.find((o) => o.id === state.selectedGroupId);
}

/**
 * Return current active profile
 * @param state
 * @returns {undefined|unknown}
 */
export const selectProductProviders: Array<productProviderT> = createSelector(
  selectCurrentAccount,
  (account) => account?.productProviders ?? [],
);

export const selectProductProvider: productProviderT | null = createSelector(selectProductProviders, (providers) =>
  providers.length ? providers[0] : null,
);

/**
 * Return google provider
 * @param account
 * @returns {*|unknown[]|undefined}
 */
export const selectGoogleProviders: Array<adsProviderT> = createSelector(
  selectCurrentAccount,
  (account) => account?.adsProviders?.filter?.((p) => p.providerType === 'GOOGLE') ?? [],
);

// Thunks
let lastUsageUpdatedTime = 0;

export function updateUsageThunk({ accountId, force = false }: { accountId: string, force?: boolean }) {
  return function (dispatch, getState, axios) {
    const newDate = new Date().getTime();

    if (newDate - lastUsageUpdatedTime > 10000 || force) {
      getAccountUsage(localStorage, axios, accountId).then((monthlyUsage) => {
        lastUsageUpdatedTime = newDate;
        dispatch(updateUsage(monthlyUsage));
      });
    }
  };
}

export function updateAccountsUsageThunk() {
  return function (dispatch, getState, axios) {
    const accountId = selectCurrentAccountId(getState());

    getAccountsUsage(localStorage, axios, { rnd: sn(getState(), 'user.id'), accountId }).then((usage) => {
      dispatch(updateAccountsUsage(usage));
    });
  };
}
