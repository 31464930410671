import { useRef, type Node } from 'react';

import { ArrowRightOutlined, CaretDownOutlined, CaretUpOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useEffectOnce, useToggle } from 'react-use';
import styled from 'styled-components';

import { ASIN_AUDITS_UPDATE, useFeatureFlag } from 'lib/configcat';
import { getIntlStrings as getStrings } from 'locales/i18n/localeHelpers';
import { useLocale } from 'locales/LocalizationWrapper';
import { useCreateAuditMutation, useUpdateAuditMutation } from 'stores/auditsSlice';

import { AUDIT_SEARCH_TYPES, AuditSearch } from './AuditSearch';
import { validateAsin, validateSellerId } from './constants';
import { ContactInformation } from './ContactInformation';

const initialValues: auditFormT = {
  searchType: AUDIT_SEARCH_TYPES.SELLER_ID,
  sellerIds: [],
  asins: [],
  competitors: {},
  contactInformation: {
    firstName: '',
    lastName: '',
    email: '',
    phone: {
      countryCode: '',
      areaCode: '',
      number: '',
    },
    notes: '',
  },
};

const StyledForm = styled(Form)`
  display: grid;
  row-gap: 2rem;
  padding: 0 0.5rem;
  .ant-btn-lg {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

const ClearButtonContainer = styled.div`
  > button {
    min-width: 120px;
  }
`;

const Divider = styled.hr`
  margin: 0 -32px;
  border: none;
  border-top: 1px solid #e6e6e6;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #fff;
  padding: 1.5rem 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  position: fixed;
  bottom: 0;
  z-index: 1;
  box-shadow: 0px 0px 22px 0px #a6a3a340;
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  > button {
    margin-left: -1rem;
    color: var(--text-muted);
    font-weight: 500;
  }
`;

const EditContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;
  > h2 {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 0;
  }
`;

const EditCard = styled.div`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  > div:first-child {
    padding: 0.75rem;
    color: var(--text-muted);
    ${(props) => props.$open && 'border-bottom: 1px solid #f2f2f2;'};
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
  > div:last-child {
    padding: 1.5rem;
    display: ${(props) => (props.$open ? 'grid' : 'none')};
    row-gap: 1rem;
  }
`;

type propsT = {
  audit?: auditT,
  backText?: string,
  onBack?: () => void,
};
export const NewAuditView = ({ audit, backText = getStrings('global.back'), onBack = () => {} }: propsT): Node => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [contactInfoOpen, toggleContactInfo] = useToggle(true);
  const [createAudit, { isLoading: isSubmitting }] = useCreateAuditMutation();
  const [updateAudit, { isLoading: isSavingContactInfo }] = useUpdateAuditMutation();
  const [isAuditUpdateEnabled] = useFeatureFlag(ASIN_AUDITS_UPDATE);
  const endRef = useRef(null);
  const { getIntlStrings } = useLocale();

  const scrollToBottom = () => {
    // Added delay to wait for components to render before calculating the scroll position
    setTimeout(() => endRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 50);
  };

  const isEdit = !!audit;

  /** Only saves contact info and remains on the view */
  const handleSaveContactInfo = async () => {
    if (!isEdit || form.getFieldError(['contactInformation', 'email']).length) {
      return;
    }

    try {
      const contactInformation = form.getFieldValue('contactInformation');

      await updateAudit({ id: audit.id, contactInformation }).unwrap();
      message.success(getIntlStrings('asinAudit.contactInformation.success'));
    } catch (err) {
      message.error(getIntlStrings('error.apiError'));
    }
  };

  /** Creates a new audit and redirects to the Audit Results view */
  const handleFinish = async (values: auditFormT) => {
    const { asins, sellerIds, competitors, searchType, contactInformation } = values;
    const payload = { contactInformation };

    if (searchType === AUDIT_SEARCH_TYPES.ASIN) {
      const validAsins = asins?.filter((x) => validateAsin(x));

      if (validAsins?.length) {
        payload.asins = validAsins.map((x) => x.toUpperCase());

        if (isAuditUpdateEnabled && competitors) {
          payload.competitors = Object.entries(competitors).reduce((acc, [asin, competitors]) => {
            const validCompetitorAsins = competitors?.filter((x) => validateAsin(x)) ?? [];

            acc[asin.toLowerCase()] = validCompetitorAsins;

            return acc;
          }, {});
        }
      }
    } else if (searchType === AUDIT_SEARCH_TYPES.SELLER_ID) {
      const validSellerIds = sellerIds?.filter((x) => validateSellerId(x));

      if (validSellerIds?.length) {
        payload.sellerId = validSellerIds[0].toUpperCase();
      }
    }

    try {
      const res = await createAudit(payload).unwrap();

      if (isEdit && onBack) {
        onBack();
      }

      history.push(`/audit/results?id=${res.id}`);
      message.success(getIntlStrings('asinAudit.newAudit.success'));
    } catch (err) {
      message.error(getIntlStrings('error.apiError'));
    }
  };

  useEffectOnce(() => {
    if (audit) {
      form.setFieldsValue({
        ...(audit.sellerId && { searchType: AUDIT_SEARCH_TYPES.SELLER_ID, sellerIds: [audit.sellerId] }),
        ...(audit.asins?.length && { searchType: AUDIT_SEARCH_TYPES.ASIN, asins: audit.asins }),
        contactInformation: audit.contactInformation,
      });
    }
  });

  return (
    <StyledForm
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
      onValuesChange={(changedValues) => {
        if (changedValues.asins || changedValues.sellerIds) {
          scrollToBottom();
        }
      }}
      layout="vertical"
      scrollToFirstError
    >
      {(values, formInstance) => (
        <>
          {isEdit ? (
            <EditContainer>
              <BackButtonContainer>
                <Button type="text" icon={<LeftOutlined />} onClick={onBack}>
                  {backText}
                </Button>
              </BackButtonContainer>
              <h2>{getIntlStrings('asinAudit.editTitle')}</h2>
              <EditCard $open={contactInfoOpen}>
                <div onClick={toggleContactInfo}>
                  {getIntlStrings('asinAudit.contactInformation.title')}
                  {contactInfoOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </div>
                <div>
                  <ContactInformation formInstance={formInstance} />
                  <SubmitButtonContainer>
                    <Button
                      type="primary"
                      size="large"
                      ghost
                      onClick={handleSaveContactInfo}
                      loading={isSavingContactInfo}
                    >
                      {getIntlStrings('asinAudit.contactInformation.submit')}
                    </Button>
                  </SubmitButtonContainer>
                </div>
              </EditCard>
            </EditContainer>
          ) : (
            <>
              <ClearButtonContainer>
                <Button size="large" danger onClick={() => form.resetFields()}>
                  {getIntlStrings('asinAudit.newAudit.clearAll')}
                </Button>
              </ClearButtonContainer>
              <ContactInformation formInstance={formInstance} />
            </>
          )}
          <Divider />
          <AuditSearch />
          <div ref={endRef} style={{ height: '100px' }} />

          <SubmitButtonContainer>
            <Button type="primary" size="large" htmlType="submit" loading={isSubmitting} formNoValidate>
              {getIntlStrings(isEdit ? 'asinAudit.newAudit.submitAnother' : 'asinAudit.newAudit.submit')}
              <ArrowRightOutlined />
            </Button>
          </SubmitButtonContainer>
        </>
      )}
    </StyledForm>
  );
};
