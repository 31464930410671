import React, { Fragment, useState } from 'react';

import { Modal } from 'antd';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import { AddANewButton } from './AddANewButton';
import { FacebookLoginButton } from '../Facebook/FacebookLoginButton';
import { GenericAdsProviderLoginButton } from '../GenericAdsProvider/GenericAdsProviderLoginButton';
import { GoogleLoginButton } from '../Google/GoogleLoginButton';
import { TiktokLoginButton } from '../Tiktok/TiktokLoginButton';

const Style = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  .ant-btn {
    display: flex;
    padding: 1em;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
  }
  .btn-text {
    margin-bottom: 0 !important;
  }
`;

export function NewAdsProviderButton({ account, title, style }) {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { messages } = useIntl();

  return (
    <Fragment>
      <Modal
        okButtonProps={{ disabled: true, style: { display: 'none' } }}
        title={title || 'Add a new ad platform'}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        visible={visible}
      >
        <Fragment>
          <Style>
            <br />
            <GoogleLoginButton account={account}></GoogleLoginButton>
            <br />
            <FacebookLoginButton
              onConnected={() => {
                setVisible(false);
              }}
              account={account}
              history={history}
            ></FacebookLoginButton>
            <br />
            <TiktokLoginButton account={account} />
            <br />
            <GenericAdsProviderLoginButton account={account} />
            <br />
            <div style={{ color: '#6D859E' }}>
              {messages.adsProvider.googleAdsPrompt} <br />
              $500 in free ad credit{' '}
              <a style={{ color: '#6D859E', fontWeight: 'bold' }} href="https://ads.google.com/intl/en_us/home/#!/">
                here!
              </a>
            </div>
          </Style>
        </Fragment>
      </Modal>
      <div style={{}}>
        <AddANewButton onClick={() => setVisible(true)} style={style}>
          + {title || 'Add a new ad platform'}
        </AddANewButton>
      </div>
    </Fragment>
  );
}
