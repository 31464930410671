import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import { stringSorter, timestampSorter, sanitizePhoneNumber, MultilineEllipsis } from './constants';
import { StatusBadge } from './StatusBadge.jsx';

const TableContainer = styled.div`
  height: calc(100vh - 105px - 4rem);
  overflow: auto;
  .ant-table-sticky-scroll {
    display: none;
  }
  thead {
    user-select: none;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TimeStamp = styled.div`
  color: #828282;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const AuditHistoryTable = ({ data, error, history, setCurrentAudit, toggleEditing }) => {
  const { messages } = useIntl();

  const columns = [
    {
      title: messages.asinAudit.contactInformation.name,
      key: 'name',
      fixed: 'left',
      width: 140,
      render: (record) =>
        [record?.contactInformation?.firstName, record?.contactInformation?.lastName].join(' ').trim() || 'N/A',
      sorter: stringSorter(['contactInformation', 'firstName']),
    },
    {
      title: messages.asinAudit.contactInformation.email,
      dataIndex: ['contactInformation', 'email'],
      width: 170,
      align: 'center',
      ellipsis: true,
      render: (val) => val || 'N/A',
      sorter: stringSorter(['contactInformation', 'email']),
    },
    {
      title: messages.asinAudit.contactInformation.phone,
      key: 'phone',
      width: 130,
      align: 'center',
      render: (v) => {
        const { areaCode, countryCode, number } = v?.contactInformation?.phone || {};

        return number ? (
          <>
            {[sanitizePhoneNumber(countryCode), sanitizePhoneNumber(areaCode), sanitizePhoneNumber(number)]
              .filter(Boolean)
              .join('-')}
          </>
        ) : (
          'N/A'
        );
      },
    },
    {
      title: messages.asinAudit.auditCreated,
      dataIndex: 'createdAt',
      width: 120,
      align: 'center',
      render: (v) => (
        <TimeContainer>
          <div>{moment(v).format('DD MMM YYYY')}</div>
          <TimeStamp>{moment(v).format('h:mm a')}</TimeStamp>
        </TimeContainer>
      ),
      sorter: timestampSorter('createdAt'),
    },
    {
      title: messages.asinAudit.auditUpdated,
      dataIndex: 'updatedAt',
      width: 120,
      align: 'center',
      render: (v) => (
        <TimeContainer>
          <div>{moment(v).format('DD MMM YYYY')}</div>
          <TimeStamp>{moment(v).format('h:mm a')}</TimeStamp>
        </TimeContainer>
      ),
      sorter: timestampSorter('updatedAt'),
    },
    {
      title: messages.asinAudit.asinsOrSellerIds,
      key: 'asinsOrSellerIds',
      width: 200,
      align: 'center',
      render: (v) => {
        const label = v?.asins?.length ? v.asins.join(', ') : v?.sellerId;

        return (
          <Tooltip title={label}>
            <MultilineEllipsis $lines={3}>{label}</MultilineEllipsis>
          </Tooltip>
        );
      },
    },
    {
      title: messages.asinAudit.contactInformation.notes,
      dataIndex: ['contactInformation', 'notes'],
      width: 200,
      align: 'center',
      render: (v) => (
        <Tooltip title={v}>
          <MultilineEllipsis $lines={3}>{v}</MultilineEllipsis>
        </Tooltip>
      ),
    },
    {
      title: messages.asinAudit.status,
      dataIndex: 'status',
      width: 120,
      align: 'center',
      render: (v) => (
        <StatusContainer>
          <StatusBadge status={v} />
        </StatusContainer>
      ),
    },
    {
      title: messages.asinAudit.action,
      key: 'action',
      fixed: 'right',
      width: 125,
      align: 'center',
      render: (v, record) => (
        <ActionsContainer>
          <Button size="large" icon={<EyeOutlined />} onClick={() => history.push(`/audit/results?id=${v.id}`)} />
          <Button
            size="large"
            icon={<EditOutlined />}
            onClick={() => {
              setCurrentAudit(record);
              toggleEditing();
            }}
          />
        </ActionsContainer>
      ),
    },
  ];

  return (
    <>
      <TableContainer>
        <Table
          dataSource={data}
          rowKey="id"
          locale={{ emptyText: error ?? '' }}
          columns={columns}
          bordered
          pagination={false}
          scroll={{ x: 'min-content' }}
          showSorterTooltip={false}
          sticky
          tableLayout="fixed"
        />
      </TableContainer>
    </>
  );
};
