// $FlowFixMe
import 'normalize.css';
import './customThemeTippy.css';
import './antd.min.css';
import './Main.css';
import React, { Component, Suspense, useContext, useEffect } from 'react';
import type { Node } from 'react';

import { useAuth0, withAuth0 } from '@auth0/auth0-react';
import {
  AxiosContextProvider,
  RegisterEmailPage,
  RoutesContext,
  RoutesContextProvider,
  UserContextProvider,
} from '@fstn/react-library';
import ConfigProvider from 'antd/lib/config-provider';
import antdEnUs from 'antd/lib/locale-provider/en_US';
import {
  ConfigCatProvider,
  createFlagOverridesFromMap,
  OverrideBehaviour,
  User,
  withConfigCatClient,
  WithConfigCatClientProps,
} from 'configcat-react';
import { useRoutes } from 'hookrouter';
import { jwtDecode } from 'jwt-decode';
import qs from 'query-string';
import { connect, Provider, useSelector } from 'react-redux';
import { useHistory, BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { Actions } from 'actions';
import { AuditPreviewPage } from 'components/pages/AsinAudit/AuditPreviewPage';
import { AuditSharePage } from 'components/pages/AsinAudit/AuditSharePage';
import { trackedEvents } from 'config/trackedEvents.config';
import { identify, page, setActive, track } from 'lib/analytics';

import { GlobalPlaceholder } from './components/common/Placeholders';
import MainLayout from './components/layout/MainLayout';
import { IncreaseCtr } from './components/pages/easy/IncreaseCtr';
import { LinkExpired } from './components/pages/easy/LinkExpired';
import { LinkPixel } from './components/pages/easy/LinkPixel';
import { LinkPreview } from './components/pages/easy/LinkPreview';
import { NotValidated } from './components/pages/notValidated/NotValidated';
import { AmazonAttributionMarketplacePage } from './components/pages/oauth/AmazonAttributionMarketplacePage';
import { AmazonAttributionProfilePage } from './components/pages/oauth/AmazonAttributionProfilePage';
import { Auth0SignIn } from './components/pages/signIn/Auth0SignIn';
import { SignOut } from './components/pages/signOut/SignOut';
import { SignUp } from './components/pages/signUp/SignUp.jsx';
import Validating from './components/pages/validating/Validating';
import { returnTo } from './index';
import { getSignedUser } from './lib/auth';
import axios from './lib/axios.factory';
import { FORCED_APP_DOMAIN } from './lib/configcat';
import { sd } from './lib/safe';
import { newVersion } from './lib/version';
import C6Navbar from './Navbar';
import { signIn, store } from './stores';

type props = {
  logged: boolean,
  user: userT,
  lastVisitedURL: string,
};

type actions = {
  signIn: (user: userT) => void,
};

type allProps = props & actions & WithConfigCatClientProps & { children: Node } & { location: Location };

const Auth0Check = ({ children }) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const token = localStorage.getItem('access-token');

    if (!token) {
      loginWithRedirect({ appState: { returnTo } });
    }

    const expiresAt = jwtDecode(token)?.exp;
    const currentTime = Math.floor(Date.now() / 1000);

    if (expiresAt <= currentTime) {
      loginWithRedirect({ appState: { returnTo } });
    }
  }, []);

  return children;
};

class secureApp extends Component<allProps, { loadingUser: boolean }> {
  constructor(props: allProps) {
    super(props);
    this.state = { loadingUser: true };
  }

  componentDidMount() {
    const { signIn, lastVisitedURL } = this.props;

    getSignedUser(localStorage, axios)
      .then((user) => {
        this.props.getValue(FORCED_APP_DOMAIN, '', new User(user.id, user.email)).then((v: string) => {
          let url = new URL(window.location.href);

          if (
            lastVisitedURL &&
            lastVisitedURL !== window.location.href &&
            !lastVisitedURL.includes('/sign-out') &&
            window.location.pathname === '/'
          ) {
            // redirect to last visited URL
            url = new URL(lastVisitedURL);
          }

          // ConfigCat does not allow empty strings, so "null" is used instead
          if (v && v !== 'null') {
            url.hostname = v;
          }

          if (url.href === window.location.href) {
            signIn(user);
            this.setState({ loadingUser: false });
          } else {
            window.location.href = url.href;
          }
        });
      })
      // no user logged => logging
      .catch((error) => {
        this.setState({ loadingUser: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, impersonated, updateLastVisitedURL } = this.props;
    const { user: prevUser } = prevProps;

    setActive(!impersonated);

    if (user) {
      if ((prevUser && prevUser.id !== user.id) || prevUser === undefined) {
        identify(user.id, {
          email: user.email,
          name: user.fullname,
        });
        track(trackedEvents.login);
      }

      page();
      updateLastVisitedURL(window.location.href);
    }

    newVersion(fetch).then((changed) => {
      if (changed) {
        window.location.reload();
      }
    });
  }

  render() {
    const { loadingUser } = this.state;

    if (loadingUser) {
      return <GlobalPlaceholder loadingText="Loading user" />;
    }

    const { logged, user, children, location } = this.props;

    if (logged) {
      if (!user.validated && !user.anonymous) {
        return (
          <Auth0Check>
            <C6Navbar />
            <Switch>
              <Route path={['/sign-out']} render={this.connectSignOut} />
              <Route path="/validate/:email/:code" render={this.connectedValidating} />
              <Route path="/" render={this.connectedNotValidated} />
            </Switch>
          </Auth0Check>
        );
      }

      if (user) {
        return (
          <Auth0Check>
            <C6Navbar />
            {children}
          </Auth0Check>
        );
      }
    }

    // Get path from url for returnTo
    const returnToPath = encodeURIComponent(`${location.pathname}${location.search}`);

    return (
      <Switch>
        <Route path={['/sign-out']} render={this.connectSignOut} />
        <Route path={['/sign-in/:email/:password', '/sign-in']} render={this.connectedSignIn} />
        <Route
          path={['/sign-up', '/appsumo', '/ltdf', '/stackcommerce', '/sign-up-c6', '/gtm', '/attribution-sign-up']}
          render={this.connectedSignUp}
        />
        <Route path={['/sign-up-waiting']} render={this.connectedNotValidated} />
        <Route path="/invited/:code" render={this.connectedAcceptInvitation} />
        <Route path="/validate/:email/:code" render={this.connectedValidating} />
        <Route
          path="/oauth/google/callback"
          render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
            if (location && location.search) {
              const { code } = qs.parse(location.search);

              return <Redirect to={`/oauth/google/callback?code=${code}`} />;
            }
          }}
        />
        <Route
          path="/oauth/amazon/callback"
          render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
            if (location && location.search) {
              const { code } = qs.parse(location.search);

              return <AmazonAttributionMarketplacePage key={code} code={code} />;
            }
          }}
        />
        <Route
          path="/oauth/amazon/profile"
          render={({ match }: { match: Match, history: RouterHistory }) => <AmazonAttributionProfilePage />}
        />
        <Redirect to={`/sign-in?returnTo=${returnToPath}`} />
      </Switch>
    );
  }

  connectSignOut = () => <SignOut />;

  connectedSignIn = ({ match }) => <Auth0SignIn />;

  connectedSignUp = ({ match }) => {
    if (match.url !== '/sign-up-c6') {
      localStorage.setItem('PIX_SIGNUP_USER_TYPE', match.url.replace('/', ''));
      this.props.history.push(`/sign-up-c6${window.location.search}`);

      return;
    }

    return <SignUp match={match} />;
  };

  connectedNotValidated = () => <NotValidated user={this.props.user} onSignOut={this.props.signOut} />;

  connectedValidating = ({ match }) => {
    if (match && match.params && match.params.email) {
      return (
        <Validating
          localStorage={localStorage}
          onSignOut={this.props.signOut}
          email={unescape(match.params.email)}
          code={unescape(match.params.code)}
        />
      );
    }

    return <GlobalPlaceholder />;
  };

  connectedAcceptInvitation = ({ match }) => {
    if (match && match.params && match.params.code) {
      const { isLoading, isAuthenticated, loginWithRedirect } = this.props.auth0;

      if (isLoading) {
        return <GlobalPlaceholder />;
      }

      if (isAuthenticated) {
        return <>error</>;
      }

      localStorage.setItem('PIX_INVITATION_CODE', match.params.code);
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          skip_permissions_flow: 'true',
        },
        appState: {
          returnTo,
        },
      });
    }

    return <GlobalPlaceholder />;
  };
}

const mapStateToProps = function (state): props {
  const userState = state.user;

  return {
    user: userState.user,
    logged: userState.logged,
    impersonated: userState.impersonated,
    lastVisitedURL: state.front.lastVisitedURL,
  };
};

const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  updateLastVisitedURL: (args) => {
    dispatch(Actions.front.lastVisitedURL.request(args));
  },
  signIn: (args) => dispatch(signIn(args)),
  signOut: () => {
    window.location.href = '/sign-out';
  },
});

const SecureApp = withConfigCatClient(withAuth0(withRouter(connect(mapStateToProps, dispatchToProps)(secureApp))));

const Routes = () => (
  <Switch>
    <Route path="/audit/share">
      <AuditSharePage />
    </Route>
    <Route path="/audit/preview">
      <AuditPreviewPage />
    </Route>
    <SecureApp>
      <MainLayout />
    </SecureApp>
  </Switch>
);

export default function () {
  const overrides = {};
  // Example code:
  // if (!!localStorage.getItem("auth0")) {
  //   overrides.AUTH0 = true;
  // }

  const flagOverrides = createFlagOverridesFromMap(overrides, OverrideBehaviour.LocalOverRemote);

  return (
    <ConfigCatProvider sdkKey={window.env.REACT_APP_CONFIG_CAT_SDK_KEY} options={{ flagOverrides }}>
      <Suspense fallback={<GlobalPlaceholder />}>
        <Router>
          <Provider store={store}>
            <RoutesContextProvider>
              <AxiosContextProvider token={localStorage.getItem('access-token')}>
                <UserContextProvider publicPagesPattens={['/', '', '/login', '/register', '/sign-in']}>
                  <ConfigProvider locale={antdEnUs}>
                    <LoginRouteSwitcher>
                      <Routes />
                    </LoginRouteSwitcher>
                  </ConfigProvider>
                </UserContextProvider>
              </AxiosContextProvider>
            </RoutesContextProvider>
          </Provider>
        </Router>
      </Suspense>
    </ConfigCatProvider>
  );
}

export function LoginRouteSwitcher({ children }) {
  const { routesCxt } = useContext(RoutesContext);
  // todo load account
  const currAccount = useSelector((states) => {
    const acc = sd(states.accounts.accounts, [])[0];

    return acc;
  });
  const ctxRoutes = {
    ...routesCxt.routes,
    '/easy/signup': () => <RegisterEmailPage />,
    '/easy/link-expired/:redirectId': ({ redirectId }) => <LinkExpired redirectId={redirectId} account={currAccount} />,
    '/easy/increase-ctr/:redirectId': ({ redirectId }) => <IncreaseCtr redirectId={redirectId} account={currAccount} />,
    '/easy/link-preview/:redirectId': ({ redirectId }) => <LinkPreview redirectId={redirectId} account={currAccount} />,
    '/easy/link-pixel/:redirectId': ({ redirectId }) => <LinkPixel redirectId={redirectId} account={currAccount} />,
  };

  delete ctxRoutes[''];
  delete ctxRoutes['/'];
  delete ctxRoutes['/login/:email'];
  delete ctxRoutes['/login'];
  delete ctxRoutes['/welcome'];
  const routes = useRoutes(ctxRoutes);

  if (routes) {
    return <>{routes}</>;
  } else {
    return <>{children}</>;
  }
}
