import React, { type Node } from 'react';

import { Form, Select } from 'antd';
import styled from 'styled-components';

import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { AutomationRuleOption } from './AutomationRuleOption';

const LeftIndentContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  padding-left: 1.5rem;
  padding-top: 1rem;

  ${(props) => props.$disabled && `opacity: 0.5`}
`;

export const OPTIONS = {
  DEFAULT: { label: getIntlStrings('campaignAutomations.options.default'), value: '' },
  ENABLED: { label: getIntlStrings('campaignAutomations.options.enabled'), value: true },
  DISABLED: { label: getIntlStrings('campaignAutomations.options.disabled'), value: false },
};

type propsT = {
  name: string | string[],
  title: string,
  description: string,
  children?: Node,
};
export const AutomationRule = ({ name, title, description, children }: propsT): Node => {
  const form = Form.useFormInstance();
  const enabled = Form.useWatch(name, form) === OPTIONS.ENABLED.value;

  return (
    <div>
      <AutomationRuleOption description={description}>
        <Form.Item name={name} label={title}>
          <Select options={Object.values(OPTIONS)} style={{ width: 155 }} />
        </Form.Item>
      </AutomationRuleOption>
      {children && (
        <LeftIndentContainer $disabled={!enabled} data-testid="automation-rule_children">
          {children}
        </LeftIndentContainer>
      )}
    </div>
  );
};
