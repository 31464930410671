import React, { useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Divider, Form, Input } from 'antd';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import * as _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { initGrowsumo } from 'lib/growsumo';

import { gtmButtonStyles, Title, C6Header } from './Theme';
import { useLoading } from '../../../hooks/useLoading';
import { returnTo } from '../../../index';
import { checkOrgCoupon } from '../../../lib/api';
import { signUp } from '../../../lib/auth';
import { GlobalPlaceholder } from '../../common/Placeholders';
import SignLayout from '../../layout/Sign/SignLayout';
import { AmazonLoginButton } from '../AmazonAttribution/AmazonLoginPage';
import { PromoApplied1 } from '../easy/images/PromoApplied1';
import { Signup } from '../easy/images/Signup';

// Apply style to all form
const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

const retrieveCoupon = AwesomeDebouncePromise((coupon: string) => checkOrgCoupon(localStorage, axios, { coupon }), 500);

export function SignUp(props) {
  const dispatch = useDispatch();

  const captchaRef = useRef(null);
  const [error, setError] = useState();
  const [form] = Form.useForm();
  const { doAction, loading: sending } = useLoading(false);

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <GlobalPlaceholder loadingText="Loading" />;
  }

  if (isAuthenticated && !localStorage.getItem('auth0-user')) {
    loginWithRedirect({
      appState: {
        returnTo,
      },
    }).then();

    return <GlobalPlaceholder loadingText="Loading" />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
      appState: {
        returnTo,
      },
    }).then();

    return <GlobalPlaceholder loadingText="Loading" />;
  }

  initGrowsumo();
  const formValues = {};

  const type = _.get(props, 'match.path', '').substr(1);

  if (type === 'gtm') {
    formValues.coupon = 'Q4CARBON6X';
  }

  const auth0User = type === 'sign-up-c6' ? JSON.parse(localStorage.getItem('auth0-user', {})) : {};

  if (auth0User.email) {
    formValues.email = auth0User.email;
  }

  form.setFieldsValue(formValues);

  localStorage.setItem('signup-theme', type);

  function handleSubmit(values) {
    if (!values.captcha && !localStorage.getItem('PIX_INVITATION_CODE')) {
      setError('Missing Captcha');

      return;
    }

    setError(undefined);
    doAction(async () => {
      try {
        const payload = {
          captcha: values.captcha,
          code: values.code,
          coupon: values.coupon,
          company: values.company,
          email: values.email,
          fullname: values.fullName,
          password: values.password,
          auth0AccessToken: auth0User.accessToken,
          invitationCode: localStorage.getItem('PIX_INVITATION_CODE'),
          type: localStorage.getItem('PIX_SIGNUP_USER_TYPE') ? localStorage.getItem('PIX_SIGNUP_USER_TYPE') : type,
        };

        try {
          payload.partnerKey = window.growsumo.data.partner_key;
        } catch (e) {}

        const newUser = await signUp(localStorage, dispatch, axios, payload);

        // Fire new "custom" event so marketing can listen for this event on GTM, and do whatever they want to do with it
        try {
          document.dispatchEvent(
            new CustomEvent('new-user-signed-up', {
              detail: {
                user: newUser,
              },
            }),
          );
        } catch (e) {}

        localStorage.setItem('auth0-access-token', auth0User.accessToken);
        localStorage.setItem('signup-with-amazon', 'false');
        localStorage.removeItem('PIX_INVITATION_CODE');
        localStorage.removeItem('PIX_SIGNUP_USER_TYPE');
        window.location.href = '/sign-up-waiting';
      } catch (e) {
        form.setFieldsValue(values);
        setError(e.errorMessage);

        throw e;
      }
    });
  }

  function renderError() {
    let err;

    switch (error) {
      case 'Override disabled for users':
        err = 'This email already exists';
        break;
      case 'Unvalid code':
        err = 'The code you entered is not valid';
        break;
      case 'Code missing':
        err = 'You need to enter your promo code';
        break;
      default:
        err = error;
    }

    return err;
  }

  if (localStorage.getItem('PIX_INVITATION_CODE')) {
    if (!sending) {
      handleSubmit({});
    }

    return <GlobalPlaceholder loadingText="Accepting invitation" />;
  }

  return (
    <SignLayout
      errorMessage={renderError()}
      theme={type}
      header={type === 'gtm' ? <C6Header /> : type === 'sign-up-c6' ? <></> : null}
      title={
        type === 'gtm' || type === 'sign-up-c6' ? <Title>Create your free account</Title> : 'Create your free account'
      }
      description={
        type === 'sign-up-c6' ? (
          <></>
        ) : (
          <div style={{ textAlign: 'center', width: '100%' }}>
            Already have an account?{' '}
            <Link className="highlight" to="/sign-out">
              Sign in
            </Link>
          </div>
        )
      }
      footer={
        <span style={{ float: 'left' }}>
          <br />
          <div>
            By signing up I agree to the{' '}
            <a className="highlight" href="https://pixelme.me/terms-privacy">
              {' '}
              PixelMe Terms and Privacy Policy{' '}
            </a>
          </div>
        </span>
      }
      image={type === 'gtm' ? <PromoApplied1 /> : <Signup />}
    >
      {type !== 'sign-up-c6' && (
        <React.Fragment>
          <AmazonLoginButton
            btnStyle={{
              padding: 10,
              background: 'white',
              boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
              borderRadius: 3,
              border: 'white',
              color: '#000000',
            }}
            title="Sign up with Amazon Attribution"
          />
          <br />
          <Divider plain orientation="horizontal">
            Or
          </Divider>
        </React.Fragment>
      )}
      <StyledForm onFinish={handleSubmit} layout="vertical" form={form}>
        {type !== 'sign-up-c6' && (
          <Form.Item name="fullName" label="Full name" rules={[{ required: true, type: 'string', min: 4 }]}>
            <Input size="large" placeholder="John Do" />
          </Form.Item>
        )}
        {type !== 'sign-up-c6' && (
          <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
            <Input size="large" placeholder="john@apple.com" />
          </Form.Item>
        )}
        <Form.Item name="company" label="Company" rules={[{ required: true, type: 'string', min: 4 }]}>
          <Input size="large" placeholder="Apple" />
        </Form.Item>
        {type !== 'sign-up-c6' && (
          <Form.Item name="password" label="Password" rules={[{ required: true, type: 'string', min: 8, max: 24 }]}>
            <Input.Password size="large" placeholder="***********" />
          </Form.Item>
        )}
        {(type === 'appsumo' || type === 'stackcommerce') && (
          <Form.Item name="code" label="Coupon code" rules={[{ required: true, type: 'string' }]}>
            <Input size="large" placeholder={type === 'appsumo' ? 'appsumo_xxxxx' : 'stack_coupon'} />
          </Form.Item>
        )}
        {(type === 'gtm' || type === 'sign-up' || type === 'sign-up-c6') && (
          // TODO (Matej): coupon is hidden temporarily, revert after  Jan 17th
          <Form.Item
            name="coupon"
            style={{ display: 'none' }}
            label="Coupon code"
            hasFeedback
            rules={[
              { required: false, type: 'string' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  return new Promise((resolve, reject) => {
                    if (!value) {
                      resolve();
                    } else {
                      retrieveCoupon(value)
                        .then((response) => {
                          if (response.coupon) {
                            resolve();
                          } else {
                            reject('Coupon is invalid or has expired');
                          }
                        })
                        .catch((e) => {
                          reject('Coupon is invalid or has expired');
                        });
                    }
                  });
                },
              }),
            ]}
          >
            <Input size="large" disabled={type === 'gtm'} />
          </Form.Item>
        )}

        <Form.Item name="captcha" rules={[{ required: true, type: 'string' }]}>
          <ReCAPTCHA ref={captchaRef} sitekey={window.env.REACT_APP_CAPTCHA_SITE_KEY} />
        </Form.Item>
        <Button
          size="large"
          block
          htmlType="submit"
          type="primary"
          style={gtmButtonStyles[type] || { background: '#2E0077', borderColor: '#2E0077' }}
          loading={sending}
        >
          Sign up for free
        </Button>
      </StyledForm>
    </SignLayout>
  );
}
