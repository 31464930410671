import React, { useEffect, useState } from 'react';

import { Alert, Modal } from 'antd';

import { useGetProductProvidersQuery } from 'stores/productProvidersSlice';

import { ProductProviderAccounts } from './ProductProviderAccounts';
import type { accountT } from '../../../../../flow-typed/pixelme/types';

let alreadyClose = false;

export function AmazonReLoginModal(props: { account: accountT }) {
  const [productProviders, setProductProviders] = useState([]);
  const [visible, setVisible] = useState(false);

  const { data } = useGetProductProvidersQuery();

  useEffect(() => {
    if (data) {
      const filteredProviders = data.providers.filter((p) => p.loginNeeded || p.spa.errorAt !== null);
      const productProvidersLoginNeeded = filteredProviders.length > 0 && !alreadyClose;

      setProductProviders(filteredProviders);
      setVisible(productProvidersLoginNeeded);
    }
  }, [data]);

  return (
    <Modal
      visible={visible}
      okButtonProps={{ disabled: true }}
      title="Amazon account was disconnected"
      width="780px"
      onCancel={() => {
        alreadyClose = true;
        setVisible(false);
      }}
    >
      <Alert
        type="error"
        message="Your account was disconnected by Amazon. Please reconnect it."
        description="If the problem persists, please contact us."
      />
      <br />
      <ProductProviderAccounts providers={productProviders} account={props.account} />
    </Modal>
  );
}
