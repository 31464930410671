import React, { Fragment, useEffect } from 'react';

// import { useDetectAdBlock } from 'adblock-detect-react';
// import { Alert } from 'antd';
import styled from 'styled-components';

import { LimitedButton } from '../../../common/antd/LimitedButton';
import { AdsProviderLogo } from '../common/AdsProviderLogo';

const ProviderButton = styled(LimitedButton)`
  height: 51px;
  padding: 1em;
  display: flex;
  gap: 1em;
`;

const Style = styled.div`
  a {
    text-decoration: none !important;
  }
  .btn-text {
    width: 180px;
  }
`;

/**
 * Tiktok Login Button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TiktokLoginButton(props: { account: accountT }) {
  // const adBlockDetected = useDetectAdBlock();

  async function load() {}

  useEffect(() => {
    load().then();
  }, []);

  // if (adBlockDetected) {
  //   return (
  //     <Alert
  //       message="We are not able to connect to your Tiktok Business Account if you are using AdBlock,"
  //       type="error"
  //       description={
  //         <Fragment>
  //           <a
  //             href="https://help.getadblock.com/support/solutions/articles/6000055743-how-to-disable-adblock-on-specific-sites"
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             How do I turn off my Ad Blocker?
  //           </a>
  //         </Fragment>
  //       }
  //     ></Alert>
  //   );
  // }

  return (
    <Style>
      <a>
        <ProviderButton
          icon={<AdsProviderLogo providerType="TIKTOK" style={{ height: 22 }} />}
          feature="TikTok Ads"
          limitationTest={(limitations, usage) => usage.tiktokAdsProvider < limitations.limitTiktokAdsProvider}
          onClick={() => {
            // eslint-disable-next-line no-undef
            window.location.href = `https://ads.tiktok.com/marketing_api/auth?app_id=7058163394024570881&state=aId:${
              props.account.id
            }&redirect_uri=${encodeURIComponent(window.location.origin)}%2Foauth%2Ftiktok%2Fcallback&rid=0svfme05yo9b`;
          }}
        >
          <p className="btn-text">Connect Tiktok Business</p>
        </ProviderButton>
      </a>
    </Style>
  );
}
