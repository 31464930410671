import { User } from 'configcat-react';

import { trackedEvents } from 'config/trackedEvents.config';
import { track } from 'lib/analytics';

import type { axiosParams } from './api';
import { apiCall } from './api';
import { sn } from './safe';
import type { accountT } from '../../flow-typed/pixelme/types';
import { store } from '../stores';
import { switchAccount } from '../stores/accounts';

// Call api to get a jwt token, call user endpoint to get a user
export function signIn(
  localStorage: any,
  axios: (axiosParams) => Promise<*>,
  email: string,
  password: string,
): Promise<userT> {
  return apiCall({
    localStorage,
    axios,
    method: 'post',
    url: '/authent',
    secure: false,
    data: { email, password },
  })
    .then((response: { accessToken: string }) => {
      const accessToken = response && response.accessToken;

      if (accessToken) {
        localStorage.setItem('access-token', accessToken);

        return getSignedUser(localStorage, axios);
      } else {
        return Promise.reject({
          message: 'Unable to read token',
          code: 'NO_TOKEN',
        });
      }
    })
    .catch((error) => Promise.reject(error));
}

export function impersonateByID(
  localStorage: any,
  axios: (axiosParams) => Promise<*>,
  userID: string,
  accessOverride: boolean = false,
): Promise<userT> {
  localStorage.setItem('impersonate', userID);
  localStorage.setItem('impersonate_access_override', accessOverride ? 'true' : 'false');

  return getSignedUser(localStorage, axios);
}

export function unImpersonateByID(localStorage: any, axios: (axiosParams) => Promise<*>): Promise<userT> {
  localStorage.removeItem('impersonate');
  localStorage.removeItem('impersonate_access_override');

  return getSignedUser(localStorage, axios);
}

// Call api to get a jwt token from another user,
// call user endpoint to get this user,
// stores current token to be able to get it back
export function impersonate(
  localStorage: any,
  axios: (axiosParams) => Promise<*>,
  email: string,
  accessOverride: boolean = false,
): Promise<userT> {
  return apiCall({
    localStorage,
    axios,
    method: 'post',
    url: '/impersonate',
    secure: true,
    data: { email, accessOverride },
  })
    .then((response: { accessToken: string }) => {
      const accessToken = response && response.accessToken;

      console.info('setting old access token: ', localStorage.getItem('access-token'));

      if (accessToken) {
        const oldAccessToken = localStorage.getItem('access-token');

        localStorage.setItem('old-access-token', oldAccessToken);
        localStorage.setItem('access-token', accessToken);

        return getSignedUser(localStorage, axios);
      } else {
        return Promise.reject({
          message: 'Unable to read token',
          code: 'NO_TOKEN',
        });
      }
    })
    .catch((error) => Promise.reject(error));
}

// Call api to get a jwt token, call user endpoint to get a user
export function unImpersonate(localStorage: any, axios: (axiosParams) => Promise<*>): Promise<userT> {
  if ((localStorage.getItem('impersonate') || '').length > 0) {
    localStorage.removeItem('impersonate');
    localStorage.removeItem('impersonate_access_override');

    return getSignedUser(localStorage, axios);
  }

  const accessToken = localStorage.getItem('old-access-token');

  if (accessToken) {
    localStorage.setItem('access-token', accessToken);

    return getSignedUser(localStorage, axios);
  }

  return Promise.reject({
    message: 'Unable to read token',
    code: 'NO_TOKEN',
  });
}

// Call api signUp and get a jwt token, call user endpoint to get a user
export function signUp(
  localStorage: any,
  dispatch,
  axios: (axiosParams) => Promise<*>,
  data: {
    fullname: string,
    email: string,
    company: string,
    password: string,
    code: string,
    coupon: string,
    captcha: string,
    partnerKey: ?string,
    invitationCode: ?string,
  },
): Promise<userT> {
  return apiCall({
    localStorage,
    axios,
    method: 'post',
    url: '/signup',
    secure: false,
    data,
  })
    .then((response: { accessToken: string, account: accountT }) => {
      const accessToken = response && response.accessToken;
      const accountId = response && response.account && response.account.id;

      if (accessToken) {
        localStorage.setItem('access-token', accessToken);
        localStorage.setItem('selected-account', response.account.id);
        dispatch(switchAccount(accountId));

        return getSignedUser(localStorage, axios);
      } else {
        return Promise.reject({
          message: 'Unable to read token',
          code: 'NO_TOKEN',
        });
      }
    })
    .catch((error) => Promise.reject(error));
}

// Signin with accessToken
export function signinWithAccessToken(
  localStorage: any,
  axios: (axiosParams) => Promise<*>,
  dispatch,
  accessToken,
  accountId,
): Promise<userT> {
  localStorage.setItem('access-token', accessToken);
  localStorage.setItem('selected-account', accountId);
  dispatch(switchAccount(accountId));

  return getSignedUser(localStorage, axios);
}

export function getSignedUser(localStorage: any, axios: (axiosParams) => Promise<*>): Promise<*> {
  return apiCall({
    localStorage,
    axios,
    method: 'get',
    url: '/user',
    secure: true,
  })
    .then((user: userT) => Promise.resolve(user))
    .catch((error) => {
      signOut(localStorage);

      return Promise.reject(error);
    });
}

export function signOut(localStorage: any): void {
  localStorage.removeItem('access-token');
  track(trackedEvents.logout);
}

export function getFeatureFlagUser(): User {
  const state = store.getState();

  const selectedAccount = sn(state, 'accounts.selected', '');
  const email = sn(state, 'user.user.email', '');
  const planType = sn(state, 'subscription.sub.plan.type', '');

  return new User(selectedAccount, email, undefined, {
    plan: planType,
    domain: window.location.hostname,
    userId: state?.user?.user?.id,
  });
}
