import React, { useContext, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Popover } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'react-file-download';

import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import axios from 'lib/axios.factory';
import { exportMetrics } from 'lib/export';

import { ENABLE_AGGREGATED_PRODUCT_EXPORT, useFeatureFlag } from '../../../lib/configcat';
import { IconInsideButton } from '../../pages/AudienceBuilder/Campaigns/campaignTree/Common';

export const ExportButton = (props: {
  account: accountT,
  product?: { id: string, externalId: string },
  theme?: 'btn' | 'icon-with-divider',
}) => {
  const { ctx } = useContext(DashboardFilterContext);

  const [enableAggregatedProductExport] = useFeatureFlag(ENABLE_AGGREGATED_PRODUCT_EXPORT);

  const [downloading, setDownloading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const download = async (level: 'account' | 'product' | 'product_agg' | 'campaign' | 'adGroup' | 'creative') => {
    setOpen(false);

    if (downloading) {
      return;
    }

    setDownloading(true);

    try {
      let from = _.get(ctx, ['adsReportsRange', 0]);

      if (from) {
        from = moment(from).toISOString();
      }

      let to = _.get(ctx, ['adsReportsRange', 1]);

      if (to) {
        to = moment(to).toISOString();
      }

      const data = await exportMetrics({
        accountId: props.account.id,
        productId: (props.product || {}).id,
        level,
        from: from || moment().utc().startOf('day').subtract(90, 'd').toISOString(),
        to,
      });

      const date = new Date().toJSON().slice(0, 10);
      const name = props.product ? props.product.externalId : props.account.name.replace(/[^A-Za-z0-9]/g, '-');

      fileDownload(data, `${date}_${name}_${level}_level.csv`);
    } catch (e) {
      console.log('err exporting metrics', e);
      message.error(
        'Unable to export data at the moment, please try again later. If the issue persists, please contact our support.',
      );
    } finally {
      setDownloading(false);
    }
  };

  const options = (
    <div style={{ display: 'flex', gap: '3px', justifyContent: 'center', alignItems: 'center' }}>
      <b>Export Grouped By:&nbsp;</b>
      {!props.product && <Button onClick={() => download('account')}>Account</Button>}
      <Button onClick={() => download('product')}>Product</Button>
      {enableAggregatedProductExport && <Button onClick={() => download('product_agg')}>Product (agg.)</Button>}
      <Button onClick={() => download('campaign')}>Campaign</Button>
      <Button onClick={() => download('adGroup')}>Ad Group</Button>
      <Button onClick={() => download('creative')}>Creative</Button>
    </div>
  );

  if (props.theme === 'icon-with-divider') {
    return (
      <>
        <IconInsideButton>
          <Popover
            placement="bottomRight"
            content={options}
            trigger="hover"
            visible={open}
            onVisibleChange={handleOpenChange}
          >
            <DownloadOutlined />
          </Popover>
        </IconInsideButton>
        <Divider type="vertical"></Divider>
      </>
    );
  }

  return (
    <Popover
      placement="bottomRight"
      content={options}
      trigger="hover"
      visible={open}
      onVisibleChange={handleOpenChange}
    >
      <Button loading={downloading}>
        <DownloadOutlined />
      </Button>
    </Popover>
  );
};
