export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_FORMAT_HOURS = 'MMM DD, YYYY HH:mm';
export const ALL_LINKS = 'ALL';
export const ALL_LINKS_FOR_CAMPAIGN = 'ALL';
export const UNCLASSIFIED_LINKS = 'NONE';
export const UNCLASSIFIED_LINKS_INTO_SUBCAMPAIGN = 'NONE';

export const ALL_REDIRECTS = 'ALL';
export const ALL_REDIRECTS_FOR_CAMPAIGN = 'ALL';
export const UNCLASSIFIED_REDIRECTS = 'NONE';
export const UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN = 'NONE';

export const MAX_REDIRECTS_COUNT = 50000;

export const CAMPAIGN_STATUSES = {
  STATUS_RETRY_IMPORT_LATER: -51,
  STATUS_FAILED_IMPORT: -50,
  STATUS_WAITING: -10,
  STATUS_WAITING_IMPORT: -5,
  STATUS_PAUSED: 1,
  STATUS_DELETED: 2,
  STATUS_REJECTED: 3,
  STATUS_ACTIVE: 4,
  STATUS_FINISHED: 5,
  STATUS_INVALID_CREATIVE: -101,
};

export const ACTIVE_CAMPAIGN_OPTIONS = {
  ACTIVE_GOOGLE: 'activeGoogle',
  ACTIVE_FACEBOOK: 'activeFacebook',
  ACTIVE_TIKTOK: 'activeTiktok',
};

export const CAMPAIGNS_TABLE_COLUMN_NAMES = {
  status: 'Status',
  impressions: 'Impressions',
  clicks: 'Clicks',
  amazonClickThroughs: 'Amazon Clickthroughs',
  adSpent: 'Ad Spend',
  averageCpc: 'CPC',
  amazonTotalAddToCart: 'Add to Cart',
  amazonTotalPurchases: 'Purchases',
  amazonTotalSales: 'Revenue',
  conversionRate: 'Conversion Rate',
  addToCartRate: 'Add to Cart Rate',
  clickThroughRate: 'Clickthrough rate',
  amazonNewToBrandPurchases: 'New to Brand Purchases',
  amazonNewToBrandSales: 'New to Brand Revenue',
  netMargin: 'Net Margin',
  roas: 'ROAS',
  acos: 'ACOS',
  referralBonus: 'Average Brand Referral Bonus',
  googleCampaign: 'Location',
  keywords: 'Keywords',
  createdAt: 'Created At',
  providerStatus: 'Provider Status',
};

export const CAMPAIGNS_TABLE_DEFAULT_COLUMNS = [
  'status',
  'impressions',
  'clicks',
  'amazonClickThroughs',
  'adSpent',
  'averageCpc',
  'amazonTotalAddToCart',
  'amazonTotalPurchases',
  'amazonTotalSales',
  'netMargin',
  'roas',
  'acos',
  'referralBonus',
  'googleCampaign',
  'keywords',
  'createdAt',
  'providerStatus',
];

export const C6_NAVBAR_HEIGHT = 48;
